<template>
  <span class="bio_shortened_url d-flex align-items-center" :title="isTrialUserObj.message">
    <p class="d-inline">{{getBioLinkAdd.domain.url }}</p>
    <span class="slug ellipsis_slug" v-tooltip="getBioLinkAdd.shorten_url" v-if="!editSlug">{{ getBioLinkAdd.shorten_url }}</span>

    <input ref="editSlugField" v-else v-model="slugValue" class="slug_name" />
    <span class="action_icons" v-if="editSlug">
      <i class="far fa-check" @click="saveSlug()" v-tooltip="'Save'"></i>
      <i class="far fa-times" @click="editSlug = false" v-tooltip="'Close'"></i>
    </span>
    <span class="action_icons p-0" v-if="!editSlug">
      <i
        v-show="!isTrialUserObj.isTrial"
        class="far fa-pencil"
        @click="editSlugInputField(true)"
        v-tooltip="'Edit Slug'"
      ></i>
      <i
        class="far fa-copy"
        v-clipboard:copy="copyURL()"
        v-clipboard:success="onCopy"
        v-clipboard:error="onError"
        v-tooltip="'Copy link to clipboard'"
      ></i>
    </span>
  </span>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(['getBioLinkAdd','getBioLinks','getSubscription']),
  },
  data() {
    return {
      editSlug: false,
      slugValue: "",
      slugPreviousValue: "",
      isTrialUserObj : {}
    };
  },
  created() {
    this.slugPreviousValue = this.getBioLinkAdd.shorten_url;
    this.slugValue = this.getBioLinkAdd.shorten_url;
    this.isTrialUserObj = this.isTrialUser(this.getSubscription)
  },
  methods: {
    copyURL(whitelabelDomain = null) {
      if (whitelabelDomain) {
        return `${whitelabelDomain}${this.getBioLinkAdd.shorten_url}`;
      }

      if (this.getBioLinkAdd.domain) {
        return `${this.getBioLinkAdd.domain.url + this.getBioLinkAdd.shorten_url}`;
      } else {
        return `https://${this.getBioLinkAdd.shorten_domain}/${this.getBioLinkAdd.shorten_url}`;
      }
    },
    editSlugInputField(check){
      this.editSlug = check
      setTimeout(() => { this.$refs.editSlugField.focus() })
    },
    async saveSlug() {
      if (!this.slugValue || this.slugValue === "") {
        this.alertMessage("Slug value could not be empty", "error");
      } else {
        this.editSlug = false;
        if (this.getBioLinkAdd.shorten_url === this.slugValue) {
          this.getBioLinkAdd.is_update_slug = false;
        } else{
          this.getBioLinkAdd.is_update_slug = true;
          this.getBioLinkAdd.shorten_url = this.slugValue;
        }

        this.getBioLinkAdd.shorten_url = this.slugValue;

        let res = await this.$store.dispatch("storeBio", "active");
        if (!res.data.status) {
          this.getBioLinkAdd.shorten_url = this.slugPreviousValue;
          this.slugValue = this.slugPreviousValue;
          this.alertMessage(res.data.message, "error");
        } else {
          this.alertMessage("Slug updated successfully.", "success");
        }
        this.getBioLinkAdd.is_update_slug = false;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.bio_link_layout {
  .bio_shortened_url {
    background: #ece9e9cc;
    border-radius: 20px;
    &:hover {
      background: #ece9e9cc;
    }
    .slug {
      color: #03265a;
      font-weight: 600;
    }
    i {
      position: relative;
      cursor: pointer;
      background: none;
    }
    .action_icons {
      background: #3988fe;
      border-radius: 20px;
      padding: 0px 0px;
    }
    .slug_name {
      background: #e0dede;
      padding: 0;
      outline: none !important;
      border: 0;
      font-weight: 600;
      height: 23px;
      width: 95px;
    }
  }
}

.ellipsis_slug {
  width: 100px !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
</style>
