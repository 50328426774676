<template>
    <div class="bio_link_main_view">

        <div class="view_inner d-flex flex-column">

            <div class="view_top d-flex align-items-center">
                <h4 class="title">Select Brand</h4>
                <div class="view-right d-flex align-items-center ml-auto">
                    <bio-shortened-url></bio-shortened-url>
                    <router-link class="cross_icon ml-auto" :to="{name: 'bio_links'}">
                        <i class="fal fa-times"></i>
                    </router-link>
                </div>

            </div>

            <div class="view_center">
                <div class="steps_dot mb-3">
                    <div class="circles">
                        <span class="dotted_circle active"></span>
                        <span class="dotted_circle"></span>
                        <span class="dotted_circle"></span>
                    </div>

                    <button @click.prevent="validateAndNavigateToContent()"
                            class="btn---cta btn-blue btn-round">
                        <span>Next</span>
                    </button>
                </div>

                <div class="block-area">
                    <div class="brand_for_bio">
                        <div class="mt-3 input_icon_text d-flex align-items-center  "
                             :class="{'input-field-error': name_required}">
                            <div class="content">
                                <div class="url">Link name</div>
                                <div class="name">
                                    <div class="validation_input">
                                        <input class="" type="text"
                                               placeholder="Give your link a name easy to remember..."
                                               v-model="getBioLinkAdd.name">
                                        <span class="input-error" v-if="name_required">
                                                    Name cannot be empty.
                                                </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="mt-3 input_icon_text d-flex align-items-center  "
                             :class="{'input-field-error': brand_required}">
                            <div class="content">
                                <div class="url">Select Brand label</div>
                                <div class="name">
                                    <div class="validation_input">
                                        <b-dropdown right class="  mt-2 dropdown-menu-right default_style_dropdown "
                                                    :no-caret="true"
                                                    ref="bio_brand_dropdown_menu">
                                            <div class="dropdown_header with-shadow d-flex align-items-center rounded_shape  "
                                                 slot="button-content">
                                                    <span class="text">{{ getSelectedBioBrand() }}</span>

                                                <span class="arrow_icon ml-auto">
                                                    <i class=" fal fa-angle-down ml-3"></i>
                                                 </span>

                                            </div>

                                            <ul class="inner ">

                                                <template v-if="getComponentBrandList.length > 0" v-for="brand in getComponentBrandList">

                                                    <li @click.prevent="selectBrandForBio(brand._id, brand.avatar)"
                                                        class="list_item_li">

                                                        <div class="d-flex align-items-center profile__box">
                                                            <div class="avatar">
                                                                <div class="brand_img "
                                                                     :style="brandImageLink(brand.avatar)"></div>
                                                            </div>
                                                            <div class="content">
                                                                <div class="name">
                                                                    {{limitTextLength(brand.name,34)}}
                                                                </div>

                                                            </div>
                                                        </div>

                                                    </li>

                                                </template>

                                                <li class="no_text_li" v-if="getComponentBrandList.length === 0">
                                                    <template v-if="getProfile.policy.can_add_brands">
                                                        You do not have any brand, please create one by clicking below
                                                        button.
                                                    </template>
                                                    <template v-else>
                                                        You do not have any brand.
                                                    </template>

                                                </li>

                                            </ul>
                                        </b-dropdown>

                                        <span class="input-error" v-if="brand_required">
                                                Please select a brand.
                                            </span>
                                    </div>
                                </div>
                            </div>
                        </div>

<!--                        <div class="btn-block  mt-3">-->
<!--                            <button @click.prevent="validateAndNavigateToContent()"-->
<!--                                    class="btn-&#45;&#45;cta btn-blue btn-round">-->
<!--                                <span>Next</span>-->
<!--                            </button>-->

<!--                        </div>-->
                    </div>
                </div>

            </div>

        </div>
    </div>

</template>
<script>
import { mapGetters } from 'vuex'
import { bioLinkTypes } from '@/state/modules/mutation-types'
import BioShortenedURL from './BioShortenedURL'
import { DEFAULT_IMAGE } from '@/common/constants'

export default (
  {
    components: {
      'bio-shortened-url': BioShortenedURL
    },
    data () {
      return {
        name_required: false,
        brand_required: false
      }
    },
    created () {
    },
    computed: {
      ...mapGetters([
        'getComponentBrandList',
        'getBioLinkAdd'])
    },
    methods: {
      selectBrandForBio (brand, image) {
        console.debug('Method::selectBrandItem > ', brand, image)
        const brandImage = (image) || DEFAULT_IMAGE
        this.$store.commit(bioLinkTypes.SET_BIO_ADD_BRAND, brand)
        this.$store.commit(bioLinkTypes.SET_BIO_ADD_IMAGE, brandImage)
        this.$refs.bio_brand_dropdown_menu.visible = false
        // this.$router.push({ name: 'select_content' })
        this.getSelectedBioBrand()
      },
      validateAndNavigateToContent () {
        this.name_required = this.requiredCheck(this.getBioLinkAdd.name)
        this.brand_required = this.requiredCheck(this.getBioLinkAdd.brand_id)
        if (!this.name_required && !this.brand_required) {
          this.$router.push({ name: 'select_content' })
        }
      }
    },
    watch: {
      'getBioLinkAdd.brand_id' (value) {
        if (value && value.length > 0) this.brand_required = false
      }
    }
  }
)
</script>

<style scoped>
    .bio_link_layout .bio_link_main_view .view_inner .view_center .steps_dot .circles {
        width: 620px;
        text-align: center;
        display: inline-block;
    }
</style>
